var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "create-booking-verify-and-confirm-price",
      "title": "Kiểm tra thông tin giá vé",
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "centered": "",
      "no-close-on-backdrop": "",
      "body-class": _vm.loading ? 'my-2' : '',
      "size": "lg"
    },
    on: {
      "show": _vm.showHandle,
      "hide": _vm.hideModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var cancel = _ref.cancel;
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return cancel();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('flight.Back')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-gradient border-right-0",
          attrs: {
            "pill": "",
            "disabled": !_vm.payments
          },
          on: {
            "click": _vm.okHandle
          }
        }, [_c('div', {
          staticClass: "d-flex-center px-25"
        }, [_vm._v(" Giữ chỗ ")])])];
      }
    }])
  }, [_c('b-overlay', {
    staticClass: "my-2",
    attrs: {
      "show": _vm.loading,
      "rounded": "sm",
      "no-fade": "",
      "spinner-variant": "primary",
      "variant": "transparent",
      "opacity": "0.85"
    }
  }, [_vm.payments ? _c('b-card', {
    staticClass: "mb-0",
    attrs: {
      "body-class": "py-50"
    }
  }, [_vm._l(_vm.bookingData.itineraries, function (trip, tripIndex) {
    return _c('div', {
      key: tripIndex
    }, [_c('div', {
      staticClass: "font-weight-bolder mb-50"
    }, [_vm._v(" Hành trình " + _vm._s(_vm.bookingData.itineraries.length > 1 ? "".concat(tripIndex + 1) : ':') + " ")]), _vm._l(trip.segments, function (segment, segmentIndex) {
      return _c('b-card', {
        key: segmentIndex,
        staticClass: "font-weight-bolder border px-2",
        attrs: {
          "no-body": ""
        }
      }, [_c('div', {
        staticClass: "my-75 fw-700"
      }, [_c('span', {
        staticClass: "mr-50 text-nowrap"
      }, [_vm._v(_vm._s("".concat(_vm.resolveAirlineFlightNumber(segment.airline, segment.flightNumber))))]), _c('span', {
        staticClass: "mr-50 text-nowrap"
      }, [_vm._v(_vm._s("".concat(segment.bookingClass)))]), _c('span', {
        staticClass: "mr-50 text-nowrap"
      }, [_vm._v(_vm._s("".concat(_vm.convertISODateTime(segment.departureTime).dayMonth)))]), _c('span', {
        staticClass: "mr-50 text-nowrap"
      }, [_vm._v(_vm._s("".concat(segment.departure).concat(segment.arrival)))]), _c('span', {
        staticClass: "mr-50 text-nowrap"
      }, [_vm._v(_vm._s("".concat(_vm.convertISODateTime(segment.departureTime).hourMin)))]), _c('span', [_vm._v(_vm._s("".concat(_vm.convertISODateTime(segment.arrivalTime).hourMin)))])])]);
    })], 2);
  }), _c('div', [_c('div', {
    staticClass: "mb-50 font-weight-bolder"
  }, [_vm._v(" Chi tiết giá: ")]), _c('b-table', {
    attrs: {
      "outline": "",
      "responsive": "",
      "bordered": "",
      "items": _vm.payments,
      "fields": ['paxs', 'price', 'tax', 'total']
    },
    scopedSlots: _vm._u([_vm._l(['paxs', 'price', 'tax', 'total'], function (column) {
      return {
        key: "head(".concat(column, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column,
            staticClass: "text-dark text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$t("reservation.".concat(data.label.toLowerCase()))) + " ")])];
        }
      };
    }), {
      key: "cell(paxs)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm.$t("reservation.".concat(item.paxType))) + " ")];
      }
    }, {
      key: "cell(price)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s("".concat(_vm.formatCurrency(item.pricingInfo.netFare))) + " ")];
      }
    }, {
      key: "cell(tax)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s("".concat(_vm.formatCurrency(item.pricingInfo.taxCalc))) + " ")];
      }
    }, {
      key: "cell(total)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(" " + _vm._s("".concat(_vm.formatCurrency(item.pricingInfo.totalCalc))) + " ")])];
      }
    }], null, true)
  })], 1), _vm.totalDiscountAmount ? _c('div', {
    staticClass: "text-right mb-50"
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_vm._v(" Chiết khấu "), _c('em', [_vm._v("(tạm tính)")]), _vm._v(": ")]), _c('span', {
    staticClass: "font-medium-3 font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.totalDiscountAmount)) + " ")])]) : _vm._e(), !_vm.isEmpty(_vm.payments) ? _c('div', {
    staticClass: "text-warning"
  }, [_c('div', {
    staticClass: "text-right mb-50"
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_vm._v(" Tổng tiền hành trình: ")]), _c('span', {
    staticClass: "font-medium-3 fw-700"
  }, [_vm._v(" " + _vm._s(_vm.getTotalOneTrip(_vm.payments)) + " ")])]), _c('div', {
    staticClass: "text-right"
  }, [_c('span', {
    staticClass: "font-italic"
  }, [_vm._v("Lưu ý: Tổng tiền chưa bao gồm phí dịch vụ")])])]) : _vm._e()], 2) : _vm._e(), !_vm.loading ? _c('b-card', {
    staticClass: "border mt-1",
    class: _vm.isEmpty(_vm.addonDetails) ? 'd-none' : ''
  }, [_c('b', {
    staticClass: "font-medium-3"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.addons')) + " ")]), _c('b-table-lite', {
    staticClass: "mb-1 rounded",
    attrs: {
      "bordered": "",
      "responsive": "",
      "thead-class": "text-nowrap fw-600 text-center",
      "tbody-class": "text-nowrap fw-600 text-center",
      "items": _vm.addonDetails,
      "fields": _vm.addonsPriceColumns
    },
    scopedSlots: _vm._u([_vm._l(_vm.addonsPriceColumns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('div', {
            key: column.label,
            staticClass: "text-dark"
          }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t("flight.".concat(data.label))) + " ")])])];
        }
      };
    }), {
      key: "cell(Passenger)",
      fn: function fn(data) {
        return [_c('span', {
          staticClass: "text-black text-nowrap font-weight-bolder",
          class: _vm.isMobileView ? 'font-small-3' : 'font-medium-1'
        }, [_vm._v(" " + _vm._s(data.item.firstName && data.item.lastName ? "".concat(data.item.lastName, " ").concat(data.item.firstName) : "".concat(data.item.paxType, " #").concat(data.item.paxId)) + " ")])];
      }
    }, {
      key: "cell(flight_segment)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('h5', {
          staticClass: "text-dark text-nowrap"
        }, [_vm._v(" " + _vm._s(item.flights) + " ")])];
      }
    }, {
      key: "cell(Airline)",
      fn: function fn(data) {
        return [_c('h5', {
          staticClass: "text-dark"
        }, [_vm._v(" " + _vm._s(data.item.airline) + " ")])];
      }
    }, {
      key: "cell(Service)",
      fn: function fn(data) {
        return [_c('h5', {
          staticClass: "text-dark",
          staticStyle: {
            "min-width": "170px"
          }
        }, [_vm._v(" " + _vm._s(data.item.serviceName) + " ")])];
      }
    }, {
      key: "cell(Price)",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('h5', {
          staticClass: "text-dark text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.addonPrice)) + " ")])];
      }
    }, {
      key: "cell(Amount)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-dark"
        }, [_c('div', {
          staticClass: "d-flex justify-content-center align-items-center"
        }, [data.item.ssrName === 'ssrBags' ? _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(234, 84, 85, 0.15)',
            expression: "'rgba(234, 84, 85, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-icon rounded-circle d-none",
          attrs: {
            "id": "btn-decrease-".concat(data.item.ssrId),
            "variant": "flat-danger",
            "disabled": data.item.amount === 1
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('handle-service', 'decrease', data.item);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "font-weight-bolder",
          attrs: {
            "icon": "MinusIcon"
          }
        })], 1) : _vm._e(), _c('span', {
          staticClass: "d-inline-block",
          staticStyle: {
            "min-width": "25px"
          }
        }, [_vm._v(" " + _vm._s(data.item.amount) + " ")]), data.item.ssrName === 'ssrBags' ? _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(0, 207, 232, 0.15)',
            expression: "'rgba(0, 207, 232, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-icon rounded-circle font-weight-bolder d-none",
          attrs: {
            "id": "btn-increase-".concat(data.item.ssrId),
            "variant": "flat-info"
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('handle-service', 'increase', data.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "PlusIcon"
          }
        })], 1) : _vm._e()], 1)])];
      }
    }, {
      key: "cell(Total)",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('h5', {
          staticClass: "text-dark text-right"
        }, [_c('b', [_vm._v(" " + _vm._s(_vm.formatCurrency(item.addonPrice * item.amount)) + " ")])])];
      }
    }], null, true)
  }), _c('hr'), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('div', [_c('strong', {
    staticClass: "d-block font-medium-2"
  }, [_vm._v(_vm._s(_vm.$t('flight.total')))]), _c('i', [_vm._v("(" + _vm._s(_vm.$t('flight.fareText')) + ")")])]), _c('strong', {
    staticClass: "font-medium-2"
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.totalPriceAddon)))])])], 1) : _vm._e(), !_vm.loading ? _c('b-card', {
    staticClass: "border mt-1",
    class: _vm.isEmpty(_vm.selectedSeatsDetail) ? 'd-none' : ''
  }, [_c('b', {
    staticClass: "font-medium-3"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.seatDetails')) + " ")]), _c('hr'), _c('b-table-lite', {
    staticClass: "mb-1 rounded",
    attrs: {
      "bordered": "",
      "responsive": "",
      "thead-class": "text-nowrap fw-600 text-center",
      "items": _vm.selectedSeatsDetail,
      "fields": _vm.seatsPriceColumns
    },
    scopedSlots: _vm._u([_vm._l(_vm.seatsPriceColumns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column.label,
            staticClass: "text-dark"
          }, [_vm._v(" " + _vm._s(_vm.$t("flight.".concat(data.label))) + " ")])];
        }
      };
    }), {
      key: "cell(Passenger)",
      fn: function fn(data) {
        return [_c('h5', {
          staticClass: "text-dark"
        }, [_c('b', [_vm._v(" " + _vm._s(data.item.passenger.firstName && data.item.passenger.lastName ? "".concat(data.item.passenger.lastName, " ").concat(data.item.passenger.firstName) : "".concat(data.item.passenger.paxType, " #").concat(data.item.passenger.paxId)) + " ")])])];
      }
    }, {
      key: "cell(flight_segment)",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('h5', {
          staticClass: "text-dark text-nowrap"
        }, [_vm._v(" " + _vm._s(item.segment.segmentFlights) + " ")])];
      }
    }, {
      key: "cell(Airline)",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('h5', {
          staticClass: "text-dark"
        }, [_vm._v(" " + _vm._s(item.segment.airline) + " ")])];
      }
    }, {
      key: "cell(Seat)",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_c('h5', {
          staticClass: "text-dark"
        }, [_vm._v(" " + _vm._s(item.seat.seat.seatName) + " ")])];
      }
    }, {
      key: "cell(Price)",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [_c('h5', {
          staticClass: "text-dark text-right font-weight-bolder"
        }, [_vm._v(" " + _vm._s(!_vm.isEmpty(item.seat.seat.fares) && item.seat.seat.fares[0].paxType === 'ALL' ? _vm.formatCurrency(item.seat.seat.fares[0].total) : !_vm.isEmpty(item.seat.seat.fares) && item.seat.seat.fares.find(function (f) {
          return f.paxType === item.passenger.paxType;
        }) ? _vm.formatCurrency(item.seat.seat.fares.find(function (f) {
          return f.paxType === item.passenger.paxType;
        }).total) : 0) + " ")])];
      }
    }], null, true)
  }), _c('hr'), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('div', [_c('strong', {
    staticClass: "d-block font-medium-2"
  }, [_vm._v(_vm._s(_vm.$t('flight.total')))]), _c('i', [_vm._v("(" + _vm._s(_vm.$t('flight.fareText')) + ")")])]), _c('strong', {
    staticClass: "font-medium-2"
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.totalSeatPrice)))])])], 1) : _vm._e(), !_vm.isEmpty(_vm.payments) ? _c('div', {
    staticClass: "text-airline fw-600 mt-1"
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_vm._v(" Tổng tiền vé: ")]), _c('span', {
    staticClass: "font-medium-3 fw-700"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.totalPriceBooking)) + " ")])]), _c('div', {
    staticClass: "text-center text-warning"
  }, [_c('span', {
    staticClass: "font-italic"
  }, [_vm._v("Lưu ý: Tổng tiền chưa bao gồm phí dịch vụ")])])]) : _vm._e(), _c('b-alert', {
    staticClass: "mt-50 mb-0",
    attrs: {
      "show": _vm.hasDiscountVN1A,
      "variant": "danger"
    }
  }, [_c('div', {
    staticClass: "alert-heading"
  }, [_c('span', {
    staticClass: "font-medium-3 fw-700"
  }, [_vm._v("Quan trọng!")])]), _c('p', {
    staticClass: "mt-50 px-1 font-medium-2 fw-700"
  }, [_vm._v(" Chúng tôi thấy rằng bạn đang đặt chuyến bay khứ hồi của VietNam Airlines có giá khuyến mãi. Vui lòng chọn chức năng \" Tách chặng\" để tính được giá khuyến mãi hoặc liên hệ booker ! ")]), _c('div', {
    staticClass: "d-flex-center pb-50"
  }, [_c('BFormCheckbox', {
    attrs: {
      "checked": _vm.isSplitItineraries
    },
    on: {
      "change": function change() {
        return _vm.$emit('update:isSplitItineraries', !_vm.isSplitItineraries);
      }
    }
  }, [_c('span', {
    staticClass: "fw-600 font-medium-3"
  }, [_vm._v(_vm._s(_vm.$t('flight.splitItineraries')))])])], 1)]), _vm.errorCheckPrice ? _c('b-col', {
    staticClass: "text-center my-3",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-danger font-weight-bolder"
  }, [_vm._v(" Lỗi xác nhận thông tin hành trình !!! ")])]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }